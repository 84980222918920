<template>
  <span v-if="value >= 50">
    <Icon v-for="ei in Math.floor(value/4000)" :key="100+ei" color="red" type="ios-ribbon" />
    <Icon v-for="ei in Math.floor((value%4000)/1000)" :key="80+ei" color="orange" type="ios-ribbon" />
    <Icon v-for="ei in Math.floor((value%1000)/250)" :key="60+ei" type="ios-ribbon" />
    <Icon v-for="ei in Math.floor((value%250)/50)" :key="ei" type="ios-ribbon-outline" />
  </span>
  <span v-else-if="value > 0">
    <Icon type="ios-ribbon-outline" color="gray" />
  </span>
</template>

<script>
  export default {
    name: 'UserStars',
    props: {
      value: Number
    }
  }
</script>

<style scoped>
</style>
