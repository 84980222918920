<template>
  <div v-if="auth.allowpost">
    <div style="display: table; width: 100%; text-align: left; margin-bottom: 5px;">
      <span v-if="!topic.closed && !inSoga" style="display: table-cell;">
        <Button v-if="auth.allowthread" @click="replyTopic" style="margin-right: 5px;"
                type="primary" icon="md-add">{{$t('page.topic.btn_reply_topic')}}</Button>
        <a v-if="false&&auth.allowtop" @click="topTopic()">
          <Icon type="ios-arrow-thin-up"></Icon>{{$t('page.topic.btn_top')}}</a>&nbsp;
        <a v-if="false&&auth.allowmove" @click="moveTopic()">
          <Icon type="ios-paperplane"></Icon>{{$t('page.topic.btn_move')}}</a>
      </span>

      <SogaPage v-if="inSoga" :current="req_page" :total="topic.total" :page-size="page_size" @on-change="page_change"
                :prev-text="label_page_prev" :next-text="label_page_next" class="pager"
                style="display: table-cell; text-align: center;"></SogaPage>
      <Page v-else :current="req_page" :total="topic.total" :page-size="page_size" @on-change="page_change"
            :prev-text="label_page_prev" :next-text="label_page_next" size="small" class="pager"
            :style="pager_style" style="display: table-cell; text-align: right;"
            :transfer="true" :show-elevator="!inSoga" show-total/>
    </div>
    <!--主体-->
    <div class="post-box" style="width: 100%;display: table;">
      <!--主题信息-->
      <div style="display: table-row;">
        <div class="topic-left-header" style="display: table-cell;" :style="post_left_style">
          {{$t('page.topic.lb_review')}}:<span class="hot-number">{{topic.views}}</span>&nbsp;|&nbsp;
          {{$t('page.topic.lb_reply')}}:<span class="hot-number">{{topic.posts}}</span>
        </div>
        <div class="topic-right-header" style="display: table-cell;">
          <Icon v-if="topic.closed" type="ios-remove-circle" size="small" />
          {{'[' + topic.flag.name + ']' + topic.subject}}
        </div>
      </div>

      <div v-for="(post,pi) in post_list" :key="pi" style="display: table-row-group;">
        <div style="display: table-row;">
          <PostUser :user="post.user" class="post-left post-box-top" style="display: table-cell;">
          </PostUser>
          <!--帖子列表-->
          <div class="post-right post-box-top" style="display: table-cell">
            <div style="width: 100%;">
              <!--标题-->
              <a v-if="!inSoga" :id="'p'+(page_size*(curr_page-1)+pi+1)"></a>
              <div class="post-header" style="width: 100%; display: table;">
                <div style="display: table-cell;">
                    <span v-if="post.user.id === topic.user_id">
                      <Icon type="ios-person"></Icon>{{$t('page.topic.v_ts')}}&nbsp;
                    </span>
                  <Icon v-else type="ios-person-outline"></Icon>
                  &nbsp;{{timeStamp(post.created_at)}}&nbsp;
                  <span style="font-size: small;" v-if="auth.allowviewip">IP:{{post.ip}}</span>
                  <span class="thumbs-up" v-if="post.likes>0" :class="post.thumbsUp ?'thumbs-up-ok':''">
                      <icon type="ios-thumbs-up" shape="circle" size="small"></icon>{{post.likes}}
                    </span>
                </div>
                <div style="display: table-cell;text-align: right;">
                  {{page_size*(curr_page-1)+pi+1}}<sup>#</sup>
                </div>
              </div>
              <!--<Divider class="dash-line" size="small" dashed></Divider>-->
              <!--内容-->
              <div class="post-content">
                <PostQuote v-if="post.quote_pid>0" :quote="post.quote_content" :tid="topic.id" :page_size="page_size"
                           @on-jump="onJumpQuote"></PostQuote>
                <div v-if="post.is_del">{{$t('page.topic.v_post_deleted')}}</div>
                <div v-else style="word-wrap: break-word;" :ref="'post_'+post.id" v-html="post.content" @mouseup="contentMouseUp(post.id)"></div>
                <div v-if="post.created_at<post.updated_at" style="width: 50%;">
                  <Divider class="dash-line" plain size="small" dashed></Divider>
                  <span style="font-size: x-small;">{{$t(post.is_del?'page.topic.lb_del_at':'page.topic.lb_edit_at')}}&nbsp;{{timeStamp(post.updated_at)}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--帖子编辑项-->
        <div v-if="!topic.closed" style="width: 100%; display: table-row;">
          <div class="post-left" style="display: table-cell;">&nbsp;</div>
          <div style="display: table-cell;">
            <Button style="margin-top:10px;margin-bottom:10px;" @click="onThumbsUp(post)"
                    icon="ios-thumbs-up" class="thumbs-up"
                    :disabled="post.thumbsUp" shape="circle" size="large"></Button>
            <Divider v-if="!inSoga" class="dash-line" size="small" dashed></Divider>
            <div v-if="!inSoga && !post.is_del" style="width: 100%; display: table;">
              <span style="text-align:left; display: table-cell;">
                <a @click="replyPost(post, page_size*(curr_page-1)+pi+1)">
                  <Icon type="ios-chatbubbles"></Icon>{{$t('page.topic.btn_reply')}}
                </a>
              </span>
              <span style="text-align: right;display: table-cell; padding-right: 10px;">
                <a @click="editPost(post)" v-if="post.user.id===$store.state.user.id || auth.allowupdate">
                    <Icon type="ios-create"></Icon>{{$t('common.btn_edit')}}</a>&nbsp;
                <a @click="delPost(post)" v-if="post.user.id===$store.state.user.id || auth.allowdelete">
                    <Icon type="ios-trash"></Icon>{{$t('common.btn_del')}}</a>
                <router-link v-else :to="{name:'report',params:{type:'post', content:{id:post.id}, html:post.message}}">{{$t('common.btn_report')}}</router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="loading" style="text-align: center;"><Spin size="large" style="display: inline-block;"></Spin>{{$t('page.data_loading')}}</div>
    <div style="display: table; width: 100%; text-align: left; margin-top: 5px;" >
      <Button v-if="!topic.closed && !inSoga && auth.allowthread" @click="replyTopic" type="primary" icon="md-add"
              style="display: table-cell;">{{$t('page.topic.btn_reply_topic')}}</Button>
      <SogaPage v-if="inSoga" :current="req_page" :total="topic.total" :page-size="page_size" @on-change="page_change"
                :prev-text="label_page_prev" :next-text="label_page_next" class="pager"
                style="display: table-cell; text-align: center;"></SogaPage>
      <Page v-else :current="req_page" :total="topic.total" :page-size="page_size" @on-change="page_change"
            :prev-text="label_page_prev" :next-text="label_page_next" size="small" class="pager"
            :style="pager_style" style="display: table-cell; text-align: right;"
            :transfer="true" :show-elevator="!inSoga" show-total/>
    </div>

    <Modal v-model="publish.show" :width="publish_width" style="max-width: 100%;" footer-hide>
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-chatbubbles"></Icon>
        <span v-if="publish.edit">{{$t('page.topic.lb_edit_post')}}</span>
        <span v-else-if="publish.quote">{{$t('page.topic.lb_reply')}}{{publish.quote.floor}}<sup>#</sup></span>
        <span v-else>{{$t('page.topic.btn_reply_topic')}}</span>
      </p>
      <Publish @on-success="onPublished" :fid="topic.forum.id" :tid="topic.id"
               :quote="publish.quote" :post="publish.edit">
      </Publish>
    </Modal>
  </div>
  <div v-else>{{$t('page.no_auth_view')}}</div>
</template>

<script>
  import { httpGet, httpPost, httpDelete } from '@/api/data';
  import util from '@/libs/util';
  import PostUser from './parts/post_user';
  import PostQuote from './parts/post_quote';
  import Publish from './parts/publish';
  import SogaPage from './parts/soga_page';

  export default {
    name: 'Topic',
    components: {
      SogaPage,
      PostUser,
      PostQuote,
      Publish
    },
    data () {
      return {
        inSoga: this.$store.state.inSoga,
        loading: true,

        pager_style: this.$store.state.inSoga ? 'text-align: center;' : '',
        label_page_prev: this.$t('page.page_prev'),
        label_page_next: this.$t('page.page_next'),
        post_left_style: '',
        page_size: 10,
        curr_page: 1,
        req_page: 1,

        quote: null,

        topic: {
          id: 0,
          subject: '',
          forum: {
            id: 0,
            name: ''
          },
          flag: {
            name: ''
          },
          total: 0,
          review_num: 0,
          reply_num: 0
        },
        post_list: [],

        link_regx: /<[^>]+>|tvbus:\/\/[^\s"<>]+|(http|https|rtp|rtsp|tvbus):\/\/[^\s".<>]+\.[^\s"<>]+/ig,
        shareIcon: require('@/assets/share.gif'),
        media_protocols: [
          'rtsp', 'rtp', 'p2p'
        ],
        media_suffixes: [
          'm3u8', 'ts', 'flv', 'mp4', 'mpd'
        ],
        // 仅tv上可用
        media_tv_suffixes: [
          'm3u'
        ],

        publish: {
          show: false,
          quote: null,
          edit: null
        },
        publish_width: '80%'
      }
    },
    computed: {
      auth () {
        const forum = this.topic ? this.topic.forum : null;
        return util.userAuth(this.$store.state, forum);
      }
    },
    methods: {
      initData () {
        let page = this.$route.query.page;
        if (typeof page === 'string') {
          page = parseInt(page);
        }
        if (typeof page === 'number') {
          if (page > 0) { this.req_page = page; }
        }
        if (window.screen.width < 730) {
          this.publish_width = '100%';
        }

        // 从路由记录中快速获取
        let forum = this.$route.params.forum;
        if (!forum) {
          const rf = this.$route.params.from;
          if (rf) {
            forum = rf.params.forum;
          }
        }
        if (forum) {
          const topic = this.$route.params.topic;
          if (topic) {
            if (!topic.flag) {
              topic.flag = {
                name: ''
              };
            }
            if (!topic.forum) {
              topic.forum = forum;
            }
            this.topic = topic;
            this.pushNav(forum, topic);
          }
        }
        this.loadPosts();
        if (this.$route.params.anchor) {
          setTimeout(() => {
            const pp = document.getElementById(this.$route.params.anchor);
            if (pp) {
              pp.scrollIntoView();
            }
          }, 200);
        }
      },
      pushNav (forum, topic) {
        this.$store.commit('nav', [
          [
            forum.name,
            {
              name: 'forum',
              query: { id: forum.id },
              params: { forum: forum }
            }
          ],
          [
            topic.subject,
            this.$route
          ]
        ]);
      },
      loadPosts (toFloor) {
        this.loading = true;
        httpGet('/v1/bbs/topic/' + this.$route.query.id + '/?pageSize=' + this.page_size + '&page=' + this.req_page)
          .then(res => {
            const forum = res.forum;
            if (forum.masters) {
              forum.mids = [];
              for (const i in forum.masters) {
                forum.mids.push(forum.masters[i].id);
              }
            }
            this.topic = res;
            this.topic.total = res.post_list.total;
            this.curr_page = res.post_list.current_page;
            const posts = res.post_list.data;

            for (const i in posts) {
              const post = posts[i];
              util.checkMaster(post.user, forum.mids);
              post.content = this.loadContent(post.message);
              if (post.quote_pid > 0) {
                if (typeof post.quote_content === 'string') {
                  post.quote_content = JSON.parse(post.quote_content);
                }
              }
            }
            this.post_list = posts;
            this.pushNav(this.topic.forum, this.topic);

            if (toFloor) {
              setTimeout(() => {
                this.toFloor(toFloor);
              }, 200);
            }
          }).catch(() => {
          }).finally(() => {
            this.req_page = this.curr_page;
            this.loading = false;
          });
      },
      appendSegment (to, content, start, pos, seg) {
        if (start < pos) {
          seg = content.substring(start, pos) + seg;
        }
        if (to) {
          return to + seg;
        }
        return seg;
      },
      // 转换帖子内容。如处理链接和图片大小
      loadContent (content) {
        // console.error(content);
        const regx = this.link_regx;
        regx.lastIndex = 0;
        const regxA = /<a\s*[^>]*>|<\/a\s*>/i;
        let value = null;
        let start = 0;
        let pos = 0;
        let depth = 0;
        while (pos >= 0) {
          const rs = regx.exec(content);
          if (!rs) {
            pos = -1;
            break;
          }
          const m = rs[0];
          pos = regx.lastIndex - m.length;
          if (m[0] !== '<') {
            if (depth !== 0) {
              continue;
            }
            const link = m;
            let href = this.processHref(m); // console.warn('href:' + href);
            if (href === null) {
              continue;
            }
            if (href === false) {
              href = m;
            }
            let seg = '<a href="' + href + '" class="share-cell-link" target="_blank">';
            // seg += '<img src="' + this.shareIcon + '" class="share-cell-image">';
            seg += link;
            seg += '</a>';
            value = this.appendSegment(value, content, start, pos, seg);
            start = regx.lastIndex;
          } else if (regxA.test(m)) {
            if (m[1] === '/') {
              depth--;
            } else {
              depth++;
              const seg = this.processA(m, content, regx.lastIndex);
              if (seg !== false) {
                value = this.appendSegment(value, content, start, pos, seg);
                start = regx.lastIndex;
              }
            }
          }
        }
        if (value == null) { return content; }
        if (pos < 0) {
          value += content.substring(start);
        }
        return value;
      },
      // 处理a标签, 返回处理后的字符串，false表示未处理
      processA (m, content, pos) {
        let q = m.indexOf('href');
        if (q > 0) {
          q = m.indexOf('"', q);
          if (q > 0) {
            const s = q + 1;
            q = m.indexOf('"', s);
            if (q > 0) {
              let href = this.processHref(m.substring(s, q));// console.warn('href:' + href);
              if (href === null) {
                // 链接无效，寻找内容
                let p = pos;
                while (p >= 0) {
                  p = content.indexOf('</a>', p);
                  if (p < 0) { return false; }
                  if (content.lastIndexOf('<a ', p) < pos) {
                    // It is!
                    href = content.substring(pos, p);
                    const regx = new RegExp(this.link_regx.source);
                    if (!regx.test(href)) { return false; }
                    const src = this.processHref(href);
                    if (src === null) { return false; }
                    if (src !== false) { href = src; }
                    break;
                  }
                  p++;
                }
              }
              if (href !== false) {
                return m.substring(0, s) + href + m.substring(q);
              }
            }
          }
        }
        return false;
      },
      // 处理链接, 返回处理后的字符串，false表示未处理
      processHref (href) {
        const q = href.indexOf('://');
        if (q <= 0) {
          if (href.trim().length === 0 || href === 'about:blank') {
            return null;
          }
          return false;
        }
        const ext = this.parseHrefExt(href);
        if (ext && !this.inSoga) {
          if (this.media_tv_suffixes.indexOf(ext) >= 0) {
            return null;
          }
        }
        // 判断protocol
        const protocol = href.substring(0, q).toLowerCase();
        if (this.media_protocols.indexOf(protocol) >= 0) {
          return this.toPlayerUrl(href);
        }

        // 判断后缀
        if (ext && this.media_suffixes.indexOf(ext) >= 0) {
          return this.toPlayerUrl(href);
        }
        return false;
      },
      parseHrefExt (href) {
        let q = href.indexOf('?');
        if (q < 0) { q = href.length; }
        if (q > 5) {
          const i = href.lastIndexOf('.', q);
          if (i > 0) {
            return href.substring(i + 1, q).toLowerCase();
          }
        }
      },
      toPlayerUrl (href) {
        return '/player.html?url=' + encodeURIComponent(href);
      },
      contentMouseUp (pid) {
        const txt = ('' + window.getSelection()).trim();
        if (txt) {
          if (this.quote === null) {
            this.quote = {};
          }
          this.quote.id = pid;
          this.quote.text = txt;
        } else {
          this.quote = null;
        }
      },
      // 处理引用内容
      loadQuote (post, floor) {
        const quote = { id: post.id, floor: floor };
        const pp = this.$refs['post_' + post.id];
        if (!pp) {
          return quote;
        }
        const maxLen = 300;
        let text = pp[0].innerText.trim();
        if (!text) {
          return quote;
        }
        if (this.quote && this.quote.id === post.id) {
          let seg = this.quote.text;
          const pos = text.indexOf(seg);
          if (pos >= 0) {
            let suffix = '';
            if (seg.length > maxLen) {
              seg = util.truncateText(seg, maxLen);
            } else if (pos + seg.length < text.length) {
              suffix = '...';
            }
            const prefix = pos > 0 ? '...' : '';
            quote.content = prefix + seg + suffix;
          }
        } else {
          this.quote = null;
        }
        text = text.replace('\n', '<br>');
        text = util.truncateText(text, maxLen);
        if (quote.content) {
          quote.full = text;
        } else {
          quote.content = text;
        }
        return quote;
      },
      page_change (page) {
        this.req_page = page;
        this.loadPosts();
      },
      checkLogin (action, params) {
        if (util.hasLogin(this.$store)) {
          return true;
        }
        if (action) {
          if (params) {
            params = {
              action: action,
              ...params
            }
          } else {
            params = {
              action: action
            };
          }
          this.$router.push({
            name: 'login',
            params: params
          });
        }
        return false;
      },
      topTopic () {

      },
      moveTopic () {

      },
      editPost (post) {
        this.publish.quote = null;
        this.publish.edit = post;
        if (!this.checkLogin('replyTopic')) {
          return;
        }
        this.publish.show = true;
      },
      delPost (post) {
        let msg = this.$t('common.confirm_delete');
        if (post.is_first) {
          msg = this.$t('page.topic.alert_del_topic') + msg;
        }
        this.$Modal.confirm({
          title: this.$t('common.alert_title'),
          content: msg,
          onOk: () => {
            this.doDelPost(post);
          }
        });
      },
      doDelPost (post) {
        if (post.is_first) {
          httpDelete('/v1/bbs/topic/' + this.topic.id, null, null, { errors: this.$t('common.tip_del_fail') }).then(() => {
            this.$router.push({
              name: 'forum',
              query: { id: this.topic.forum.id },
              params: { forum: this.topic.forum }
            });
          }).catch(() => {
          });
        } else {
          httpDelete('/v1/bbs/post/' + post.id, null, null, { errors: this.$t('common.tip_del_fail') }).then(() => {
            this.$Message.info(this.$t('common.tip_del_ok'));
            post.message = null;
            post.content = null;
            post.is_del = true;
            post.updated_at = Math.floor((new Date().getTime()) / 1000);
          }).catch(() => {
          });
        }
      },
      replyTopic () {
        this.publish.edit = null;
        this.publish.quote = null;
        if (!this.checkLogin('replyTopic')) {
          return;
        }
        this.publish.show = true;
      },
      replyPost (post, floor) {
        this.publish.edit = null;
        this.publish.quote = this.loadQuote(post, floor);
        if (!this.checkLogin('replyPost', { publish: this.publish })) {
          return;
        }
        this.publish.show = true;
      },
      timeStamp (tt) {
        return util.showTimeStamp(tt, true, 0);
      },
      onPublished (res) {
        if (this.publish.edit) {
          const post = this.publish.edit;
          this.publish.edit = null;
          post.message = res.message;
          post.content = this.loadContent(res.message);
          post.updated_at = res.updated_at;
        } else {
          this.onJumpQuote(this.topic.total + 1, true);
        }
        this.publish.quote = null;
        this.publish.show = false;
      },
      toFloor (floor) {
        const pp = document.getElementById('p' + floor);
        if (pp) {
          pp.scrollIntoView();
        }
      },
      onJumpQuote (floor, reload) {
        this.req_page = Math.ceil(floor / this.page_size);
        if (this.req_page !== this.curr_page || reload) {
          this.loadPosts(floor);
        } else {
          this.toFloor(floor);
        }
      },
      onThumbsUp (post) {
        this.$set(post, 'thumbsUp', true);
        httpPost('/v1/bbs/post/' + post.id + '/like', null, null, { errors: 1020000 }).then(() => {
          post.likes++;
        }).catch(() => {
          this.$set(post, 'thumbsUp', false);
        });
      }
    },
    created () {
      let thumbSize = 150;
      if (window.screen.width < 730) {
        thumbSize = 120;
        this.label_page_prev = '<';
        this.label_page_next = '>';
      }
      this.post_left_style = 'width: ' + thumbSize + 'px;min-width:' + thumbSize + 'px;';
    },
    mounted () {
      this.initData();
      const action = this.$route.params.action;
      if ((action === 'replyTopic' || action === 'replyPost') && this.checkLogin()) {
        const publish = this.$route.params.publish;
        if (publish) {
          this.publish = publish;
        }
        this.publish.show = true;
      }
    }
  }
</script>

<style scoped>
  img {
    max-width:100%;
  }
  .dash-line {
    margin: 5px 0 5px 0;
  }
  .post-box {
    border: lightblue solid 1px;
  }
  .post-box-top {
    border-top: lightblue solid 1px;
  }
  .post-left {
    text-align: center;
    background-color: lightskyblue;
  }
  .post-right {
    text-align: left;
  }
  .post-content {
    padding: 5px;
  }
  .topic-left-header {
    text-align: left;
    background-color: lightskyblue;
    padding: 5px;
    font-size: x-small;
  }
  .topic-right-header {
    text-align: left;
    font-weight: bold;
    padding: 5px;
    background: url("../assets/titlebg.png");
  }
  .post-header {
    padding: 5px;
    background-color: #f4f8ff
  }
  span.thumbs-up {
    color: white;
    border: white solid 1px;
    background-color: orangered;
    padding-left:2px;
    padding-right:3px;
    width:20px;
    height:20px;
    border-radius: 12px;
  }
  span.thumbs-up-ok {
    background-color: #cb4cff;
  }
  .thumbs-up {
    color: white;
    border: white solid 1px;
    background-color: orangered;
  }
  .thumbs-up:disabled {
    background-color: #cb4cff;
  }
</style>
