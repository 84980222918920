<template>
  <div class="post-user">
    <div class="post-user-area">
      <UserName :user="user" class="post-user-name"></UserName>
    </div>
    <Divider class="dash-line" size="small" dashed></Divider>
    <SogaLink><router-link :to="'user?id=' + user.id">
      <UserHead :user="user" class="post-user-head"></UserHead>
    </router-link></SogaLink>
    <div style="display: table; width: 100%;" class="stat-area">
      <span class="right-border" style="display: table-cell; width: 34%;">
        {{bbs_user.posts}}<br>{{$t('page.topic.lb_post')}}
      </span>
      <span class="right-border" style="display: table-cell; width: 32%;">
        {{bbs_user.topics}}<br>{{$t('page.topic.lb_topic')}}
      </span>
      <span style="display: table-cell; width: 34%;">{{bbs_user.golds}}<br>{{$t('page.topic.lb_score')}}</span>
    </div>
    <div class="post-user-info">
      <!-- 称号 -->
      <div v-if="user.title">{{user.title}}</div>
      <UserStars :value="bbs_user.credits"></UserStars>
      <div>{{$t('page.topic.lb_reg_time')}}&nbsp;{{regTime}}</div>
      <div v-if="alive_time>0">{{$t('page.topic.lb_alive_time')}}&nbsp;{{aliveTime}}</div>
    </div>
  </div>
</template>

<script>
  import UserName from './user_name';
  import UserHead from './user_head';
  import UserStars from './user_stars';
  import SogaLink from './soga_link';

  export default {
    name: 'PostUser',
    components: {
      SogaLink,
      UserHead,
      UserStars,
      UserName
    },
    props: {
      user: {
        id: Number,
        nick: String,
        uname: String,
        head: String,
        created_at: {
          type: Number,
          default: 0
        },
        bbs_user: Object
      }
    },
    computed: {
      bbs_user () { // api过渡
        return this.user.bbs_user ? this.user.bbs_user : this.user;
      },
      regTime () {
        const d = new Date(this.user.created_at * 1000);
        let s = d.getFullYear();
        s += '-';
        const mm = d.getMonth() + 1;
        if (mm < 10) { s += '0'; }
        s += mm;
        s += '-';
        const dd = d.getDate();
        if (dd < 10) { s += '0'; }
        s += dd;
        return s;
      },
      alive_time () {
        return 0;
        // return (new Date().getTime() / 1000) - this.bbs_user.login_time;
      },
      aliveTime () {
        let ss = this.alive_time;
        let s = Math.floor(ss % 60) + '秒';
        if (ss < 60) {
          return s;
        }

        ss = Math.floor(ss / 60);
        s = ((ss % 60) + '分') + s;
        if (ss < 60) {
          return s;
        }
        ss = Math.floor(ss / 60);
        s = (ss + '時') + s;
        return s;
      }
    }
  }
</script>

<style scoped>
  .dash-line {
    margin: 5px 0 5px 0;
  }
  .right-border {
    border-right: lightgray solid 1px;
  }
  .post-user {
    text-align: center;
  }
  .post-user-head {
    width: 100%; height: auto;
  }
  .post-user-area {
    text-align: left;
    padding-left: 5px;
  }
  .stat-area {
    margin-bottom: 5px;
    font-size: x-small;
  }
  .post-user-name {
    font-weight: bold;
    color: darkblue;
    overflow: scroll;
  }
  .post-user-info {
    text-align: left;
    padding-left: 5px;
    font-size: x-small;
  }
</style>
