<template>
  <img :src="this.headSrc" v-bind="$attrs">
</template>

<script>
  export default {
    name: 'UserHead',
    props: {
      user: {
        id: Number,
        head: String,
        sex: -1
      }
    },
    computed: {
      headSrc () {
        if (this.user.head) {
          return this.user.head;
        }
        if (this.user.sex === 0) {
          return require('@/assets/head0.jpg')
        }
        if (this.user.sex === 1) {
          return require('@/assets/head1.jpg')
        }
        return require('@/assets/head.jpg')
      }
    }
  }
</script>

<style scoped>
</style>
